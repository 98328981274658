export const latestpostTranslate: { [key: string]: string } = {
  en: 'Latest Posts',
};

export const otherPostTranslate: { [key: string]: string } = {
  en: 'Other Post',
};

export const footerTranslations: {
  [key: string]: {
    quickLink: string;
    home: string;
    about: string;
    privacyPolicy: string;
    categories: string;
    maharashtra: string;
  };
} = {
  en: {
    quickLink: 'Quick link',
    home: 'Home',
    about: 'About',
    privacyPolicy: 'Privacy Policy',
    categories: 'Categories',
    maharashtra: 'Maharashtra',
  },
};

export const NEXT_CACHE = 25 * 24 * 60 * 60;
export const CATEGORY_NEXT_CACHE = 2 * 60 * 60;
