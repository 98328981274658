interface Link {
  title: string;
  url: string;
  langauge: string;
}

export const headerLinks: Link[] = [
  {
    title: 'Home',
    url: '/',
    langauge: 'en',
  },
  {
    title: 'SEO',
    url: 'seo',
    langauge: 'en',
  },
  {
    title: 'Tutorials',
    url: 'tutorials',
    langauge: 'en',
  },
  {
    title: 'Design',
    url: 'design',
    langauge: 'en',
  },
  {
    title: 'Marketing',
    url: 'marketing',
    langauge: 'en',
  },
  {
    title: 'branding',
    url: 'branding',
    langauge: 'en',
  },
];
