export const supportedLanguages = [
  { name: 'English', shortCode: 'en' },
  { name: 'Afrikaans', shortCode: 'af' },
  { name: 'Arabic', shortCode: 'ar' },
  { name: 'Bengali', shortCode: 'bn' },
  { name: 'Bosnian', shortCode: 'bs' },
  { name: 'Bulgarian', shortCode: 'bg' },
  { name: 'Czech', shortCode: 'cs' },
  { name: 'Danish', shortCode: 'da' },
  { name: 'Dutch', shortCode: 'nl' },
  { name: 'Estonian', shortCode: 'et' },
  { name: 'Finnish', shortCode: 'fi' },
  { name: 'French', shortCode: 'fr' },
  { name: 'German', shortCode: 'de' },
  { name: 'Gujarati', shortCode: 'gu' },
  { name: 'Hindi', shortCode: 'hi' },
  { name: 'Hungarian', shortCode: 'hu' },
  { name: 'Indonesian', shortCode: 'id' },
  { name: 'Italian', shortCode: 'it' },
  { name: 'Japanese', shortCode: 'ja' },
  { name: 'Kannada', shortCode: 'kn' },
  { name: 'Korean', shortCode: 'ko' },
  { name: 'Malay', shortCode: 'ms' },
  { name: 'Malayalam', shortCode: 'ml' },
  { name: 'Maori', shortCode: 'mi' },
  { name: 'Marathi', shortCode: 'mr' },
  { name: 'Mongolian', shortCode: 'mn' },
  { name: 'Nepali', shortCode: 'ne' },
  { name: 'Norwegian', shortCode: 'no' },
  { name: 'Persian', shortCode: 'fa' },
  { name: 'Polish', shortCode: 'pl' },
  { name: 'Portuguese', shortCode: 'pt' },
  { name: 'Punjabi', shortCode: 'pa' },
  { name: 'Romanian', shortCode: 'ro' },
  { name: 'Russian', shortCode: 'ru' },
  { name: 'Serbian', shortCode: 'sr' },
  { name: 'Sinhala', shortCode: 'si' },
  { name: 'Slovenian', shortCode: 'sl' },
  { name: 'Spanish', shortCode: 'es' },
  { name: 'Tajik', shortCode: 'tg' },
  { name: 'Tamil', shortCode: 'ta' },
  { name: 'Telugu', shortCode: 'te' },
  { name: 'Thai', shortCode: 'th' },
  { name: 'Turkish', shortCode: 'tr' },
  { name: 'Ukrainian', shortCode: 'uk' },
  { name: 'Urdu', shortCode: 'ur' },
  { name: 'Vietnamese', shortCode: 'vi' },
];
