import { supportedLanguages } from 'helper/constant/supported-languages';

export const createNavigation = (
  lang: string,
  category?: string,
  slug?: string
) => {
  const isLang = !!supportedLanguages.find((language) => {
    return language.shortCode === lang;
  });

  if (isLang && lang !== 'en') {
    if (category && !slug) {
      return `/${lang}/${category}/`;
    } else if (category && slug) {
      return `/${lang}/${category}/${slug}`;
    } else {
      return `/${lang}`;
    }
  } else {
    if (category && !slug) {
      return `/${category}`;
    } else if (slug) {
      return `/${category}/${slug}`;
    } else {
      return '/';
    }
  }
};

export const topicNavigation = (lang: string, topicSlug?: string) => {
  const isLang = !!supportedLanguages.find((language) => {
    return language.shortCode === lang;
  });
  if (isLang && lang !== 'en') {
    if (topicSlug) {
      return `/topic/${lang}/${topicSlug}`;
    }
    return `/topic/${lang}`;
  } else {
    if (topicSlug) {
      return `/topic/${topicSlug}`;
    }
    return `/topic/`;
  }
};
